import React from 'react';
import Header from '../Header/header';

class Unverification extends React.Component {
  state = {
    conformation: false,
  };

  render() {
    return (
      <div className="page-account-verification">
        <Header hideSubHeader={true} />
        <div className="page-body">
          {this.state.conformation ? (
            // <h2 className="page-title">Please verify your email address </h2>
            <h2 className="page-title">You can proceed to login</h2>
          ) : (
            <div>
              <h1 className="page-title text-success text-center">
                Welcome to AI Facilitator Community!
              </h1>
              <h2 className="page-title text-center">
                You have Successfully Registered!
              </h2>
              {/* <h2 className="page-title text-center">
                Please verify your email address
              </h2> */}
              {/* <p className="page-content text-center">
                To complete your registration process, you must verify your
                email address. An email containing verification instructions has
                been sent to your inbox. Please login to your mail account and
                click on the verification link.
              </p> */}
              <p className="page-content text-center">
                Congratulations on becoming a member of the AI Facilitator Community
                (AIFC)! We are thrilled to have you join us on this exciting
                journey of learning, exploring, and connecting with like-minded
                individuals passionate about artificial intelligence.
              </p>
              <p className="page-content text-center">
                Dive in and start exploring today!
              </p>
              {/* <p className="page-content text-center alert alert-info font-weight-bold fz-16">
                In case you didn't receive the email, please check your email's
                spam folder.
              </p> */}
              <div className="text-center">
                <a href="/login">
                  <button className="btn-theme-primary btn-lg border-radius mt-3 mb-3 px-4">
                    Login to Your Account
                  </button>
                </a>
                {/* <a href="/">
                  <button className="btn-theme-primary btn-lg border-radius mt-3 mb-3 px-4">
                    Home
                  </button>
                </a> */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Unverification;
